import { render, staticRenderFns } from "./PetitionCreateModal.vue?vue&type=template&id=494e7cba&scoped=true&"
import script from "./PetitionCreateModal.vue?vue&type=script&lang=js&"
export * from "./PetitionCreateModal.vue?vue&type=script&lang=js&"
import style0 from "./PetitionCreateModal.vue?vue&type=style&index=0&id=494e7cba&prod&lang=css&"
import style1 from "./PetitionCreateModal.vue?vue&type=style&index=1&id=494e7cba&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "494e7cba",
  null
  
)

export default component.exports