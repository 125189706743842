var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h2',{staticClass:"m-0 mb-2"},[_vm._v("File Petition")]),_c('validation-observer',{ref:"petitionCreateFormValidation"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('div',[_c('b-row',[_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label-for":"name"}},[_c('label',[_vm._v("Full Name")]),_c('validation-provider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","disabled":"","state":errors.length > 0 ? false : null,"placeholder":"Applicant's Full Name"},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label-for":"cnic"}},[_c('label',[_vm._v("CNIC")]),_c('validation-provider',{attrs:{"name":"cnic","rules":"required|integer|positive|length:13"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"cnic","disabled":"","state":errors.length > 0 ? false : null,"placeholder":"1234567890001"},model:{value:(_vm.cnic),callback:function ($$v) {_vm.cnic=$$v},expression:"cnic"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label-for":"description"}},[_c('label',[_vm._v("Subject")]),_c('validation-provider',{attrs:{"name":"Description","rules":{
                  required: _vm.required,
                  regex: /^(?=.*[a-zA-Z])[a-zA-Z0-9\s,\'@#\-!&*]+$/,
                }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"text-black",attrs:{"id":"description","state":errors.length > 0 ? false : null,"placeholder":"Subject","title":"Enter subject of your petition."},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row'),_c('b-form-group',[_c('quill-editor',{staticClass:"text-black",attrs:{"options":_vm.option},model:{value:(_vm.content),callback:function ($$v) {_vm.content=$$v},expression:"content"}})],1),_c('b-form-group',[_c('b-button',{staticClass:"mr-1 float-right",attrs:{"type":"button","variant":"outline-secondary","pill":""},on:{"click":_vm.reset}},[_vm._v(" Reset ")]),_c('b-button',{staticClass:"mr-1 float-right",attrs:{"id":"submit","type":"submit","variant":"primary","pill":""},on:{"click":_vm.validationForm}},[_vm._v(" Submit ")])],1)],1)])],1),_c('b-overlay',{attrs:{"show":_vm.show,"spinner-variant":"primary","no-wrap":""}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }