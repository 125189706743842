<template>
  <div>
    <div class="mb-1">
      <!-- <b-button variant="primary" pill @click="createPetition">
        <feather-icon icon="PlusIcon" class="mr-50" />
        <span class="align-middle">Create</span>
      </b-button> -->
      <b-card>
        <PetitionCreateModal @modalClosed="onModalClosedCreate" />
      </b-card>
    </div>
    <b-card>
      <!-- <div class="float-right mb-1">
        <span style="margin: 5px"
          ><span
            style="width: 18px; height: 18px"
            id="coas"
            class="mr-50 bullet table-info bullet-sm" />
          <b-tooltip target="coas" title="Marked to COAS"></b-tooltip
        ></span>
        <span style="margin: 5px"
          ><span
            style="width: 18px; height: 18px"
            id="AG"
            class="mr-50 bullet AGBranchColorClass bullet-sm" />
          <b-tooltip target="AG" title="Marked to AG branch"></b-tooltip
        ></span>
        <span style="margin: 5px"
          ><span
            style="width: 18px; height: 18px"
            id="PA"
            class="mr-50 bullet table-danger bullet-sm" />
          <b-tooltip target="PA" title="Marked to PA DTE"></b-tooltip
        ></span>
        <span style="margin: 5px"
          ><span
            style="width: 18px; height: 18px"
            id="PS"
            class="mr-50 bullet table-success bullet-sm" />
          <b-tooltip target="PS" title="Marked to PS DTE"></b-tooltip
        ></span>
        <span style="margin: 5px"
          ><span
            style="width: 18px; height: 18px"
            id="W&R"
            class="mr-50 bullet WRDTEColorClass bullet-sm" />
          <b-tooltip target="W&R" title="Marked to W&R DTE"></b-tooltip
        ></span>
        <span style="margin: 5px"
          ><span
            style="width: 18px; height: 18px"
            id="Med"
            class="mr-50 bullet MedColorClass bullet-sm" />
          <b-tooltip target="Med" title="Marked to Medical DTE"></b-tooltip
        ></span>
        <span style="margin: 5px"
          ><span
            style="width: 18px; height: 18px"
            id="Housing"
            class="mr-50 bullet table-warning bullet-sm" />
          <b-tooltip target="Housing" title="Marked to Housing DTE"></b-tooltip
        ></span>
        <span style="margin: 5px"
          ><span
            style="width: 18px; height: 18px"
            id="PP&A"
            class="mr-50 bullet PPADTEColorClass bullet-sm" />
          <b-tooltip target="PP&A" title="Marked to PP&A DTE"></b-tooltip
        ></span>
        <span style="margin: 5px"
          ><span
            style="width: 18px; height: 18px"
            id="Law"
            class="mr-50 bullet lawDTEColorClass bullet-sm" />
          <b-tooltip target="Law" title="Marked to Law DTE"></b-tooltip
        ></span>
        <span style="margin: 5px"
          ><span
            style="width: 18px; height: 18px"
            id="CP"
            class="mr-50 bullet CPColorClass bullet-sm" />
          <b-tooltip target="CP" title="Marked to CP DTE"></b-tooltip
        ></span>
        <span style="margin: 5px"
          ><span
            style="width: 18px; height: 18px"
            id="Regs"
            class="mr-50 bullet REGSColorClass bullet-sm" />
          <b-tooltip target="Regs" title="Marked to Regs DTE"></b-tooltip
        ></span>
        <span style="margin: 5px"
          ><span
            style="width: 18px; height: 18px"
            id="JAG"
            class="mr-50 bullet JAGDTEColorClass bullet-sm" />
          <b-tooltip target="JAG" title="Marked to JAG DTE"></b-tooltip
        ></span>
      </div> -->

      <b-card-body class="mt-2">
        <div
          class="mt-2 mb-2"
          style="border: 1px solid #ccc; border-radius: 5px; padding: 10px"
        >
          <b-row>
            <!-- <b-col md="2">
              <b-form-group>
                <label>Title</label>
                <b-form-input
                  id="title"
                  v-model="petitionTitle"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  placeholder="Petition Title"
                  class="text-black"
                />
              </b-form-group>
            </b-col> -->
            <b-col md="2">
              <b-form-group>
                <label>Subject</label>
                <b-form-input
                  id="Subject"
                  v-model="petitionSubject"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  placeholder="Petition Subject"
                  class="text-black"
                />
              </b-form-group>
            </b-col>
            <!-- <b-col md="2">
            <b-form-group label="Download Status">
              <v-select
                id="download_status"
                v-model="downloadStatus"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="searchOptions"
                placeholder="Select Status"
                label="text"
              />
            </b-form-group>
          </b-col> -->
            <!-- <b-col md="2">
              <b-form-group >
                <label>Marked to</label>
                <v-select
                  id="marked_to"
                  v-model="marked_to"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="markedOptions"
                  placeholder="Marked to"
                  label="text"
                  class="text-black"
                />
              </b-form-group>
            </b-col> -->
            <!-- <b-col md="2">
              <b-form-group >
                <label>
                  Draft
                </label>
                <v-select
                  id="Draft"
                  v-model="draftStatus"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="searchOptions"
                  placeholder="Select Status"
                  label="text"
                  class="text-black"
                />
              </b-form-group>
            </b-col> -->
            <b-col md="2">
              <b-form-group>
                <label> Status </label>
                <v-select
                  id="Status"
                  v-model="petitionStatus"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="statusOptions"
                  placeholder="Select Status"
                  label="text"
                  class="text-black"
                />
              </b-form-group>
            </b-col>
            <b-col
              md="2"
              v-if="hasRole('op') || hasRole('su') || hasRole('op_admin')"
            >
              <b-form-group>
                <label> Name </label>
                <b-form-input
                  id="applicantName"
                  v-model="applicantName"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  placeholder="Name"
                  class="text-black"
                />
              </b-form-group>
            </b-col>
            <b-col
              md="2"
              v-if="hasRole('op') || hasRole('su') || hasRole('op_admin')"
            >
              <b-form-group>
                <label> CNIC </label>
                <b-form-input
                  id="applicantCNIC"
                  v-model="applicantCNIC"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  placeholder="CNIC"
                  class="text-black"
                />
              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-form-group>
                <label> Case Number </label>
                <b-form-input
                  id="case_no"
                  v-model="applicant_case_no"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  placeholder="Applicant's Case No."
                  class="text-black"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col class="text-right mb-1" style="margin: auto">
              <div style="display: flex; justify-content: flex-end">
                <b-button
                  variant="primary"
                  pill
                  style="margin-right: 2%"
                  @click="searchPetitionViaSearchButton"
                >
                  <feather-icon icon="SearchIcon" class="mr-50" />
                  Search
                </b-button>
              </div>
            </b-col>
          </b-row>
        </div>

        <b-table
          v-if="hasRole('op') || hasRole('su') || hasRole('op_admin')"
          responsive="sm"
          :fields="fields_admin"
          :paginated="true"
          :items="petitions"
          details-td-class="m-0 p-0"
          sticky-header="1500px"
          small
          class="text-black"
        >
          <template #cell(location)="row">
            <span>
              {{ getPetitionLocationValue(row.item.location) }}
            </span>
          </template>
          <template #cell(status)="row">
            <span>
              {{ getPetitionStatusValue(row.item.status) }}
            </span>
          </template>
          <template #cell(is_received)="row">
            <span>
              {{ row.item.is_received ? "Yes" : "No" }}
            </span>
          </template>

          <template #cell(manage)="row">
            <b-button
              variant="secondary"
              pill
              size="sm"
              v-if="hasRole('su')"
              @click="viewPetition(row.item)"
            >
              View</b-button
            >
            <b-button
              v-if="row.item.document_details.length !== 0 && hasRole('su')"
              variant="info"
              @click="showPetitionDocuments(row.item)"
              pill
              size="sm"
            >
              Documents</b-button
            >

            <b-button
              variant="danger"
              pill
              size="sm"
              v-if="row.item.draft == true"
              @click="deleteSelectedPetition(row.item)"
              :disabled="row.item.draft !== true"
            >
              Delete</b-button
            >

            <b-button
              variant="success"
              pill
              size="sm"
              style="margin-right: 2%"
              @click="receiveSelectedPetition(row.item)"
              v-if="!row.item.is_received"
              :disabled="row.item.draft == true"
            >
              <feather-icon icon="CheckIcon" class="mr-50" />
              Receive
            </b-button>

            <b-button
              variant="warning"
              pill
              size="sm"
              style="margin-right: 2%"
              @click="changeStatustoClose(row.item)"
              v-if="row.item.status !== 3"
            >
              Confirm Close
            </b-button>
          </template>
        </b-table>

        <b-table
          v-else
          responsive="sm"
          :fields="fields_normal_user"
          :paginated="true"
          :items="petitions"
          details-td-class="m-0 p-0"
          sticky-header="700px"
          small
          class="text-black"
        >
          <!-- <template #cell(draft)="row">
            <span>
              {{ row.item.draft ? "Yes" : "No" }}
            </span>
          </template> -->
          <!-- <template #cell(marked_to)="row">
            <span>
              {{ getPetitionMarkedToValue(row.item.marked_to) }}
            </span>
          </template> -->
          <template #cell(location)="row">
            <span>
              {{ getPetitionLocationValue(row.item.location) }}
            </span>
          </template>
          <template #cell(status)="row">
            <span>
              {{ getPetitionStatusValue(row.item.status) }}
            </span>
          </template>
          <template #cell(is_received)="row">
            <span>
              {{ row.item.is_received ? "Yes" : "No" }}
            </span>
          </template>
          <template #cell(manage)="row">
            <b-button
              variant="secondary"
              pill
              size="sm"
              @click="viewPetition(row.item)"
            >
              View</b-button
            >
            <b-button
              v-if="row.item.document_details.length !== 0"
              variant="info"
              @click="showPetitionDocuments(row.item)"
              pill
              size="sm"
            >
              Documents</b-button
            >
          </template>
        </b-table>
        <b-row>
          <b-col md="8">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalDataLength"
              :per-page="per_page"
            />
          </b-col>
          <b-col md="4">
            <div style="float: right">
              <h5 style="margin: 0; display: inline" class="text-primary">
                Count:
              </h5>
              <h5 style="margin: 0; display: inline" align="right">
                <strong>
                  {{ totalDataLength }}
                </strong>
              </h5>
            </div>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <b-overlay :show="show" spinner-variant="primary" no-wrap />
    <!-- <PetitionCreateModal
      @modalClosed="onModalClosedCreate"
      :key="`create-${petitionCreateModalCount}`"
    /> -->
    <petition-view-modal
      :petition="petitionData"
      :key="`edit-${PetitionViewModalCount}`"
      @modalClosed="onModalClosed"
    />
    <petition-documents-modal
      :petitionDocuments="petitionDocuments"
      :key="`document-${petitionDocumentModalCount}`"
      :draft="draft"
      :petitionID="petitionID"
      @modalClosed="onModalClosed"
    />
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import util from "@/util.js";
import PetitionCreateModal from "@/components/petition/PetitionCreateModal.vue";
import PetitionViewModal from "@/components/petition/PetitionViewModal.vue";
import PetitionDocumentsModal from "@/components/petition/PetitionDocumentsModal.vue";
export default {
  components: {
    PetitionCreateModal,
    PetitionViewModal,
    PetitionDocumentsModal,
  },
  data() {
    return {
      petitionCreateModalCount: 0,
      petitions: [],
      totalDataLength: 0,
      per_page: 0,
      petitionTitle: null,
      downloadStatus: null,
      applicantCNIC: null,
      selectedItems: [],
      searchOptions: [
        { text: "Yes", value: "True" },
        { text: "No", value: "False" },
      ],
      // marked_to: null,
      // markedOptions: [
      //   { value: 1, text: "COAS" },
      //   { value: 2, text: "AG Branch" },
      //   { value: 3, text: "PA" },
      //   { value: 4, text: "PS" },
      //   { value: 5, text: "W&R" },
      //   { value: 6, text: "Med" },
      //   { value: 7, text: "Housing" },
      //   { value: 8, text: "PP&A" },
      //   { value: 9, text: "Law" },
      //   { value: 10, text: "CP" },
      //   { value: 11, text: "Regs" },
      //   { value: 12, text: "JAG" },
      // ],
      draftStatus: null,
      applicant_case_no: null,
      statusOptions: [
        { text: "Pending", value: 1 },
        { text: "Processing", value: 2 },
        { text: "Closed", value: 3 },
      ],
      petitionStatus: null,
      petitionSubject: null,
      show: false,
      petitionDocuments: null,
      applicantName: null,
      currentPage: 1,
      PetitionViewModalCount: 0,
      petitionDocumentModalCount: 0,
      draft: false,
      petitionID: null,
      petitionData: null,
      fields_admin: [
        { key: "case_no", label: "Case No", sortable: true },
        { key: "name", label: "Name", sortable: true },
        { key: "cnic", label: "CNIC", sortable: true },
        // { key: "title", label: "Title", sortable: true },
        // { key: "subject", label: "Subject", sortable: true },
        // { key: "location", label: "Location", sortable: true },
        { key: "status", label: "Status", sortable: true },
        { key: "is_received", label: "Received", sortable: true },
        // {
        //   key: "documents_length",
        //   label: "Documents Count",
        //   sortable: true,
        // },
        // { key: "final_comment", label: "Final Comments", sortable: true },
        { key: "updated_at", label: "Updated At", sortable: true },
        {
          key: "updated_by_data.username",
          label: "Updated By",
          sortable: true,
        },
        { key: "manage", label: "Manage" },
      ],
      fields_normal_user: [
        { key: "case_no", label: "Case No", sortable: true },
        { key: "name", label: "Name", sortable: true },
        { key: "cnic", label: "CNIC", sortable: true },
        // { key: "title", label: "Title", sortable: true },
        // { key: "subject", label: "Subject", sortable: true },
        { key: "status", label: "Status", sortable: true },
        { key: "is_received", label: "Received", sortable: true },
        // { key: "final_comment", label: "Final Comments", sortable: true },
        { key: "manage", label: "Manage" },
      ],
    };
  },
  mixins: [util],
  async mounted() {
    await this.getPetitionsData();
  },
  methods: {
    ...mapActions({
      getPetitions: "appData/getPetitions",
      deletePetition: "appData/deletePetition",
      partialUpdatePetition: "appData/partialUpdatePetition",
      update_status_to_close_send_sms:
        "appData/update_status_to_close_send_sms",
    }),
    createPetition() {
      this.petitionCreateModalCount += 1;
      this.$nextTick(() => {
        this.$bvModal.show("petition-create-modal");
      });
    },
    async onModalClosedCreate() {
      this.$bvModal.hide("petition-create-modal");
      await this.getPetitionsData();
    },
    async onModalClosed() {
      await this.searchPetitionWithFilters();
    },
    async searchPetitionViaSearchButton() {
      this.currentPage = 1;
      await this.searchPetitionWithFilters();
    },
    async searchPetitionWithFilters() {
      let payload = {};
      payload["pageNumber"] = this.currentPage;
      // if (this.downloadStatus !== null) {
      //   payload["is_downloaded"] = this.downloadStatus.value;
      // }
      // if (this.draftStatus !== null) {
      //   payload["draft"] = this.draftStatus.value;
      // }
      if (this.petitionStatus !== null) {
        payload["status"] = this.petitionStatus.value;
      }
      if (this.petitionTitle !== null) {
        payload["title"] = this.petitionTitle;
      }
      if (this.petitionSubject !== null) {
        payload["subject"] = this.petitionSubject;
      }
      // if (this.marked_to !== null) {
      //   payload["marked_to"] = this.marked_to.value;
      // }
      if (this.applicantName !== null) {
        payload["name"] = this.applicantName;
      }
      if (this.applicantCNIC !== null) {
        payload["cnic"] = this.applicantCNIC;
      }
      if (this.applicant_case_no !== null) {
        payload["case_no"] = this.applicant_case_no;
      }
      await this.getPetitionsData(payload);
    },
    viewPetition(item) {
      this.PetitionViewModalCount += 1;
      this.petitionData = item;
      this.$nextTick(() => {
        this.$bvModal.show("petition-view-modal");
      });
    },

    onSelected(item) {
      if (this.selectedItems.includes(item)) {
        const index = this.selectedItems.indexOf(item);
        this.selectedItems.splice(index, 1);
      } else {
        this.selectedItems.push(item);
      }
      if (this.selectedItems && this.selectedItems.length) {
        this.selectedItems.forEach((obj) => {});
      }
    },

    showPetitionDocuments(petition) {
      this.petitionDocumentModalCount += 1;
      this.petitionDocuments = petition.document_details;
      this.petitionID = petition.id;
      this.draft = petition.draft;
      this.$nextTick(() => {
        this.$bvModal.show("petition-documents-modal");
      });
    },
    async changeStatustoClose(petition) {
      try {
        this.show = true;
        await this.$swal({
          title: "Do you want to update the petition status?",
          text: "SMS will be sent to the recipient, You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, close this petition!",
          customClass: {
            confirmButton: "btn btn-success",
            cancelButton: "btn btn-outline-danger ml-1",
          },
          buttonsStyling: false,
        }).then(async (result) => {
          if (result.value) {
            const res = await this.update_status_to_close_send_sms({
              type: "PETITION",
              id: petition.id,
            });
            if (res.status === 200) {
              this.$swal({
                title: res.data.msg,
                icon: "success",
                timer: 3500,
              });
            } else {
              this.$swal({
                title: "Unable to mark selected petitions as received",
                icon: "error",
                timer: 3000,
              });
            }
          }
        });
        this.show = false;
        try {
          if (this.petitions) {
            if (this.petitions.length <= 1) {
              this.currentPage -= 1;
            }
          }
        } catch (e) {}
        if (this.currentPage < 1) {
          this.currentPage = 1;
        }
        await this.getPetitionsData();
      } catch (error) {
        this.displayError(error);
      }
    },
    async receiveSelectedPetition(petition) {
      try {
        this.show = true;
        await this.$swal({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, receive it!",
          customClass: {
            confirmButton: "btn btn-success",
            cancelButton: "btn btn-outline-danger ml-1",
          },
          buttonsStyling: false,
        }).then(async (result) => {
          if (result.value) {
            const res = await this.partialUpdatePetition({
              id: petition.id,
              is_received: true,
            });
            if (res.status === 200) {
              this.$swal({
                title: res.data.msg,
                icon: "success",
                timer: 1500,
              });
            } else {
              this.$swal({
                title: "Unable to mark selected petitions as received",
                icon: "error",
                timer: 3000,
              });
            }
          }
        });
        this.show = false;
        try {
          if (this.petitions) {
            if (this.petitions.length <= 1) {
              this.currentPage -= 1;
            }
          }
        } catch (e) {}
        if (this.currentPage < 1) {
          this.currentPage = 1;
        }
        await this.getPetitionsData();
      } catch (error) {
        this.displayError(error);
      }
    },

    async deleteSelectedPetition(item) {
      try {
        this.show = true;
        await this.$swal({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, delete it!",
          customClass: {
            confirmButton: "btn btn-danger",
            cancelButton: "btn btn-outline-danger ml-1",
          },
          buttonsStyling: false,
        }).then(async (result) => {
          if (result.value) {
            const res = await this.deletePetition({ id: item.id });
            if (res.status === 200) {
              this.$swal({
                title: res.data.msg,
                icon: "success",
                timer: 1500,
              });
            } else {
              this.$swal({
                title: "Unable to delete Petition",
                icon: "error",
                timer: 3000,
              });
            }
          }
        });
        this.show = false;
        try {
          if (this.petitions) {
            if (this.petitions.length <= 1) {
              this.currentPage -= 1;
            }
          }
        } catch (e) {}
        if (this.currentPage < 1) {
          this.currentPage = 1;
        }
        await this.getPetitionsData();
      } catch (error) {
        this.displayError(error);
      }
    },
    rowClass(item, type) {
      // const colorClassLaw = "table-success";
      // const colorClassLaw = "border-dark";

      const colorClassLaw = "lawDTEColorClass";
      const colorClassPS = "table-success";
      const colorClasshousing = "table-warning";
      // const colorClassWR = "table-danger";
      const colorClassWR = "WRDTEColorClass";
      // const colorClassAG = "table-dark";
      const colorClassAG = "AGBranchColorClass";
      const colorClassCOAS = "table-info";
      const colorClassMed = "MedColorClass";
      const colorClassPA = "table-danger";
      const colorClassPPA = "PPADTEColorClass";
      const colorClassJAG = "JAGDTEColorClass";
      const colorClassRegs = "REGSColorClass";
      const colorClassCP = "CPColorClass";
      if (!item || type !== "row") {
        return;
      }

      switch (item.marked_to) {
        case 1:
          return colorClassCOAS;
        case 2:
          return colorClassAG;
        case 3:
          return colorClassPA;
        case 4:
          return colorClassPS;
        case 5:
          return colorClassWR;
        case 6:
          return colorClassMed;
        case 7:
          return colorClasshousing;
        case 8:
          return colorClassPPA;
        case 9:
          return colorClassLaw;
        case 10:
          return colorClassCP;
        case 11:
          return colorClassRegs;
        case 12:
          return colorClassJAG;
        default:
          return;
      }
    },

    async getPetitionsData(searchParams = {}) {
      try {
        this.show = true;
        const res = await this.getPetitions(searchParams);
        this.petitions = res.data.results;
        this.per_page = res.data.per_page;
        this.totalDataLength = res.data.count;
        this.show = false;
      } catch (error) {
        this.show = false;
        this.displayError(error);
      }
    },
  },
  computed: {
    ...mapGetters({ getUser: "appData/getUser", hasRole: "appData/hasRole" }),
    petitionsComputedData() {
      try {
        if (this.petitions) {
          if (this.petitions.length > 0) {
            const petitionData = [];
            this.petitions.map((pet) => {
              let petition_document_length = null;
              try {
                petition_document_length = pet.document_details.length;
              } catch (e) {}
              petitionData.push({
                id: pet.id,
                name: pet.name,
                cnic: pet.cnic,
                title: pet.title,
                subject: pet.subject,
                marked_to:
                  pet.marked_to === 1
                    ? "COAS"
                    : pet.marked_to === 2
                    ? "AG Branch"
                    : pet.marked_to === 3
                    ? "PA"
                    : pet.marked_to === 4
                    ? "PS"
                    : pet.marked_to === 5
                    ? "W&R"
                    : pet.marked_to === 6
                    ? "Med"
                    : pet.marked_to === 7
                    ? "Housing"
                    : pet.marked_to === 8
                    ? "PP&A"
                    : pet.marked_to === 9
                    ? "Law"
                    : pet.marked_to === 10
                    ? "CP"
                    : pet.marked_to === 11
                    ? "Regs"
                    : "JAG",
                location:
                  pet.location === 1
                    ? "Inside"
                    : pet.location === 2
                    ? "Outside"
                    : "Call Center",
                case_no: pet.case_no,
                status:
                  pet.status === 1
                    ? "Pending"
                    : pet.status === 2
                    ? "Processing"
                    : "Closed",
                documents_length: petition_document_length,
                final_comment: pet.final_comment,
                updated_at: pet.updated_at,
                updated_by: pet.updated_by,
                document_details: pet.document_details,
                draft: pet.draft,
              });
            });
            return petitionData;
          }
        }
      } catch (error) {
        return [];
      }
    },
  },

  watch: {
    currentPage: {
      async handler() {
        await this.searchPetitionWithFilters();
      },
    },
  },
};
</script>
<style>
label {
  font-size: 110%;
  font-weight: 550 !important;
}
</style>
<style lang="scss">
@import "~@assets/scss/variables/_variables.scss";

.lawDTEColorClass {
  background-color: $light-brown-color-var;
  color: white;
}
.MedColorClass {
  background-color: $light-red-medical-color;
  color: white;
}
.WRDTEColorClass {
  background-color: $WR-DTE-Color;
  color: white;
}
.PPADTEColorClass {
  background-color: $PPA-DTE-Color;
  color: white;
}
.JAGDTEColorClass {
  background-color: $JAG-DTE-Color;
  color: white;
}
.REGSColorClass {
  background-color: $REGS-DTE-Color;
  color: white;
}
.CPColorClass {
  background-color: $CP-DTE-Color;
  color: white;
}
.AGBranchColorClass {
  background-color: $AG-BRANCH-Color;
  color: white;
}
</style>
