<template>
  <div>
    <b-modal
      id="petition-documents-modal"
      title="Petition Documents"
      centered
      hide-footer
      size="lg"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
    >
      <vue-perfect-scrollbar
        :settings="perfectScrollbarSettings"
        class="scroll-area"
        style="height: 32vh"
      >
      
        <b-card
          v-for="data in petitionDocuments"
          style="margin-left: 1%; margin-right: 1%; cursor: pointer"
          :key="data.id"
        >
      
          <div>
            <b-row style="text-align: center">
              <b-col class="mb-2">
                <h1 class="text-primary font-weight-bold">
                  {{ data.name  }}
                </h1>
                
              </b-col>
            </b-row>
            <b-row style="text-align: center">
              <b-col>
                <b-button
                  variant="primary"
                  class="ml-2 mr-2"
                  @click="downloadFile(data.path)"
                >
                  Download
                </b-button>
                <b-button
                  variant="danger"
                  class="ml-2 mr-2"
                  @click="deleteSelectedDocument(data.id)"
                  >Delete</b-button
                >
              </b-col>
            </b-row>
          </div>
        </b-card>
      </vue-perfect-scrollbar>
      <div class="text-center">
        <!-- <b-button
          variant="outline-primary"
          @click="showAddDocumentModal"
          :disabled="draft !== true"
        >
          Add Documents
        </b-button> -->
        <b-button
          variant="outline-primary"
          @click="showAddDocumentModal"
          v-if="draft"
        >
          Add Documents
        </b-button>
      </div>
    </b-modal>
    <petition-add-document-modal
      @modalClosed="onModalClosed"
      :key="`add-${petitionDocumentAddModalCount}`"
      :petitionId="petitionID"
    />
  </div>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import PetitionAddDocumentModal from "./PetitionAddDocumentModal.vue";
import { mapActions } from "vuex";
import util from "@/util.js";
import axios from "axios";
export default {
  props: {
    petitionDocuments: Array,
    draft: Boolean,
    petitionID: Number,
  },
  mixins: [util],
  data() {
    return {
      perfectScrollbarSettings: {
        maxScrollbarLength: 150,
        wheelPropagation: false,
        useBothWheelAxes: false,
        suppressScrollX: true,
      },
      petitionDocumentAddModalCount: 0,
    };
  },
  components: {
    VuePerfectScrollbar,
    PetitionAddDocumentModal,
  },
  methods: {
    ...mapActions({
      deletePetitionDocument: "appData/deletePetitionDocument",
    }),
    async downloadFile(partialPath) {
      try {
        
        // let initialPath = process.env.VUE_APP_API.replace('/api/','/media/');
        
      // const file_path = initialPath + partialPath;
      const file_path = partialPath;
        let temp_path_arr = file_path.split("/");
        
        const file_name = temp_path_arr[temp_path_arr.length - 1];
        const res2 = await axios({
          method: "GET",
          responseType: "blob",
          url: file_path,
        });
        const file = window.URL.createObjectURL(new Blob([res2.data]));
        let link = document.createElement("a");
        link.href = file;
        link.setAttribute("download", file_name);
        document.body.appendChild(link);
        link.click();
      } catch (error) {
        this.displayError(error);
      }
    },
    onModalClosed() {
      this.$bvModal.hide("petition-add-document-modal");
      this.$bvModal.hide("petition-documents-modal");
      this.$emit("modalClosed");
    },
    async deleteSelectedDocument(id) {
      try {
        await this.$swal({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, delete it!",
          customClass: {
            confirmButton: "btn btn-danger",
            cancelButton: "btn btn-outline-danger ml-1",
          },
          buttonsStyling: false,
        }).then(async (result) => {
          if (result.value) {
            
            const res = await this.deletePetitionDocument({ id });
            if (res.status === 200) {
              this.$swal({
                title: res.data.msg,
                icon: "success",
                timer: 1500,
              });
              this.$bvModal.hide("petition-documents-modal");
              this.$emit("modalClosed");
            } else {
              this.$swal({
                title: "Unable to delete Document",
                icon: "error",
                timer: 3000,
              });
            }
          }
        });
      } catch (error) {
        this.displayError(error);
      }
    },
    showAddDocumentModal() {
      this.petitionDocumentAddModalCount += 1;
      this.$nextTick(() => {
        this.$bvModal.show("petition-add-document-modal");
      });
    },
  },
};
</script>
