<template>
  <div>
    <h2 class="m-0 mb-2">File Petition</h2>
    <validation-observer ref="petitionCreateFormValidation">
      <b-form @submit.prevent>
        <div>
          <b-row>
            <b-col md="4">
              <b-form-group label-for="name">
                <label>Full Name</label>
                <validation-provider
                  #default="{ errors }"
                  name="name"
                  rules="required"
                >
                  <b-form-input
                    id="name"
                    disabled
                    v-model="name"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Applicant's Full Name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group label-for="cnic">
                <label>CNIC</label>
                <validation-provider
                  #default="{ errors }"
                  name="cnic"
                  rules="required|integer|positive|length:13"
                >
                  <b-form-input
                    id="cnic"
                    disabled
                    v-model="cnic"
                    :state="errors.length > 0 ? false : null"
                    placeholder="1234567890001"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- <b-col md="4">
              <b-form-group label-for="title">
                <label>Title</label>
                <validation-provider
                  #default="{ errors }"
                  name="Title"
                  :rules=" { required, regex: /^(?=.*[a-zA-Z])[a-zA-Z0-9\s,\'@#\-!&*]+$/ }"
                >
                  <b-form-input
                    id="title"
                    v-model="title"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Title"
                    maxlength="65"
                    class="text-black"
                    v-b-tooltip.hover
                    title="Enter a suitable title for your petition."
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col> -->

            <b-col md="4">
              <b-form-group label-for="description">
                <label>Subject</label>
                <validation-provider
                  #default="{ errors }"
                  name="Description"
                  :rules="{
                    required,
                    regex: /^(?=.*[a-zA-Z])[a-zA-Z0-9\s,\'@#\-!&*]+$/,
                  }"
                >
                  <b-form-input
                    id="description"
                    v-model="description"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Subject"
                    class="text-black"
                    v-b-tooltip.hover
                    title="Enter subject of your petition."
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <!-- <b-col md="4">
          <validation-provider
            #default="{ errors }"
            name="Mark To"
            rules="required"
          >
            <label>Mark To</label>
            <b-form-group
              label-for="markTo"
              :state="errors.length > 0 ? false : null"
            >
              <v-select
                id="markTo"
                v-model="selectedMarkTo"
                :options="organizations"
                placeholder="Mark To"
                label="text"
                class="text-black"
              />
              <b-form-invalid-feedback
                :state="errors.length > 0 ? false : null"
              >
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
             </b-col> -->
            <!-- <b-col md="4">
              <b-form-group label-for="documents">
                <validation-provider #default="{ errors }" name="Document">
                  <label>Document (PDF only)</label>
                  <b-form-file
                    ref="fileInput"
                    id="documents"
                    placeholder="Choose a file or drop it here..."
                    drop-placeholder="Drop file here..."
                    accept=".pdf"
                    @change="upload"
                  />
                  <small class="text-danger" v-if="documentError === 1"
                    >Max {{ maxFilesCount }} files are allowed</small
                  >
                  <small class="text-danger" v-if="documentError === 2"
                    >Each file size should be less than
                    {{ maxFileSize }} MB</small
                  >
                  <small class="text-danger" v-if="documentError === 3"
                    >Only pdf is allowed</small
                  >
                  <div class="d-flex flex-wrap">
                    <b-badge
                      pill
                      variant="primary"
                      v-for="(file, index) in uploadedFiles"
                      :key="index"
                      class="my-1 mx-25"
                    >
                      {{ file.name }}
                      <feather-icon
                        size="24"
                        icon="XIcon"
                        class="cursor-pointer"
                        @click="removeFile(index)"
                      />
                    </b-badge>
                  </div>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col> -->
          </b-row>
          <b-form-group>
            <quill-editor
              v-model="content"
              :options="option"
              class="text-black"
            />
          </b-form-group>
          <b-form-group>
            <!-- <b-button
            id="draft"
            type="submit"
            variant="outline-success"
            pill
            class="mr-1 text-left"
            @click="saveAsDraft"
          >
            Save as Draft
          </b-button> -->
            <b-button
              type="button"
              variant="outline-secondary"
              @click="reset"
              class="mr-1 float-right"
              pill
            >
              Reset
            </b-button>
            <b-button
              id="submit"
              type="submit"
              variant="primary"
              pill
              class="mr-1 float-right"
              @click="validationForm"
            >
              Submit
            </b-button>
          </b-form-group>
        </div>
      </b-form>
    </validation-observer>
    <b-overlay :show="show" spinner-variant="primary" no-wrap />
  </div>
  <!-- </b-modal> -->
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import { quillEditor } from "vue-quill-editor";
import util from "@/util.js";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    quillEditor,
  },

  mixins: [util],
  data() {
    return {
      required,
      show: false,
      title: "",
      name: "",
      cnic: "",
      documentError: 0,
      maxFilesCount: 5,
      maxFileSize: 5,
      description: "",
      option: {
        theme: "snow",
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"], // toggled buttons
            ["blockquote", "code-block"],
            [{ header: 1 }, { header: 2 }], // custom button values
            [{ list: "ordered" }, { list: "bullet" }],
            [{ script: "sub" }, { script: "super" }], // superscript/subscript
            [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ color: [] }, { background: [] }], // dropdown with defaults from theme
            [{ font: [] }],
            [{ align: [] }],
            ["clean"], // remove formatting button
          ],
        },
      },
      content: "",
      draft: false,
      selectedMarkTo: 2,
      organizations: [
        { value: 1, text: "COAS" },
        { value: 2, text: "AG Branch" },
        { value: 3, text: "PA" },
        { value: 4, text: "PS" },
        { value: 5, text: "W&R" },
        { value: 6, text: "Med" },
        { value: 7, text: "Housing" },
        { value: 8, text: "PP&A" },
        { value: 9, text: "Law" },
        { value: 10, text: "CP" },
        { value: 11, text: "Regs" },
        { value: 12, text: "JAG" },
      ],
      uploadedFiles: [],
      acceptedFileTypes: ".pdf",
    };
  },
  methods: {
    ...mapActions({
      createPetition: "appData/createPetition",
      getPetitions: "appData/getPetitions",
    }),

    async upload(event) {
      this.documentError = 0;
      const files = event.target.files;
      if (this.uploadedFiles.length + files.length > this.maxFilesCount) {
        this.documentError = 1;
        return;
      }
      if (!files[0].name.includes(".pdf")) {
        this.documentError = 3;
        return;
      }

      const filesToAdd = [];

      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        if (file.size > this.maxFileSize * 1000 * 1024) {
          this.documentError = 2;
          return;
        }

        filesToAdd.push(file);
      }

      this.uploadedFiles = [...this.uploadedFiles, ...filesToAdd];
    },
    removeFile(index) {
      this.documentError = 0;
      this.uploadedFiles.splice(index, 1);
    },

    async saveAsDraft() {
      this.draft = true;
      await this.validationForm();
    },

    async validationForm() {
      const success = await this.$refs.petitionCreateFormValidation.validate();
      if (success) {
        let invalidType = false;
        try {
          if (this.uploadedFiles) {
            for (let i = 0; i < this.uploadedFiles.length; i++) {
              if (
                this.acceptedFileTypes.indexOf(
                  this.uploadedFiles[i].name.split(".")[1]
                ) < 0
              ) {
                invalidType = true;
                break;
              }
            }
          }
        } catch (e) {}
        if (!invalidType) {
          await this.submit();
        }
      }
    },

    async submit() {
      this.show = true;
      if (this.documentError === 0) {
        try {
          let formData = new FormData();
          this.uploadedFiles.map((file, index) => {
            formData.append(`file${index}`, file);
          });
          formData.append(
            "data",
            JSON.stringify({
              title: this.title,
              subject: this.description,
              name: this.name,
              cnic: this.cnic,
              marked_to: this.selectedMarkTo,
              content: this.content,
              draft: this.draft,
              created_by: this.getLoggedInUser.id,
              updated_by: this.getLoggedInUser.id,
            })
          );
          const res = await this.createPetition(formData);

          if (res.status === 200) {
            this.displaySuccess(
              "Your Petition has successfully been submitted"
            );
            this.show = false;
            this.reset();
            this.$refs.petitionCreateFormValidation.reset();
          }
        } catch (error) {
          this.show = false;
          this.displayError(error);
          this.reset();
        }
      } else {
        this.show = false;
        this.$swal({
          title: `File size should be less than ${this.maxFileSize} MB or Total files should be less than ${this.maxFilesCount}`,
          icon: "error",
          timer: 3000,
        });
        this.documentError = 0;
        this.uploadedFiles = [];
        this.$refs.fileInput.reset();
        this.$refs.petitionCreateFormValidation.reset();
      }

      this.$emit("modalClosed");
    },
    reset() {
      // this.selectedMarkTo = null;
      this.title = null;
      this.description = "";
      this.content = "";
      this.uploadedFiles = [];
      // this.$refs.fileInput.reset();
      this.$refs.petitionCreateFormValidation.reset();
    },
  },
  async mounted() {
    const userData = await this.getUser;
    this.cnic = userData.cnic;
    this.name = userData.name;
  },
  computed: {
    ...mapGetters({
      getUser: "appData/getUser",
      getLoggedInUser: "appData/getUser",
    }),
  },
};
</script>
<style>
.text-black {
  color: black !important;
  /* font-size: 1.2rem; */
}
/* label {
  font-size: 110%;
  font-weight: 550;
  color: #000;
} */
</style>

<style lang="scss" scoped>
@import "@core/scss/vue/libs/quill.scss";
// label {
//   font-size: 110%;
//   font-weight: 550;
//   color: #000;
// }
</style>
