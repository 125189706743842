<template>
  <b-modal
    id="petition-view-modal"
    title="Petition View"
    centered
    hide-footer
    size="lg"
    :no-close-on-esc="true"
    :no-close-on-backdrop="true"
  >
    <template #modal-title>
      <h2 class="m-0">Petition Details</h2>
    </template>

    <validation-observer ref="petitionViewValidation">
      <b-form @submit.prevent>
        <b-row>
          <b-col cols="6">
            <label>Case No</label>
            <b-form-input
              id="case_no"
              v-model="case_no"
              placeholder="case_no"
              :disabled="true"
            />
          </b-col>
          <b-col cols="6">
            <b-form-group label="CNIC" label-for="cnic">
              <validation-provider
                #default="{ errors }"
                name="cnic"
                rules="required|integer|positive|length:13"
              >
                <b-form-input
                  id="name"
                  v-model="cnic"
                  disabled
                  :state="errors.length > 0 ? false : null"
                  placeholder="CNIC"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="6">
            <b-form-group label="Full Name" label-for="fullfullname">
              <validation-provider
                #default="{ errors }"
                name="fullname"
                rules="required"
              >
                <b-form-input
                  id="fullname"
                  v-model="name"
                  disabled
                  :state="errors.length > 0 ? false : null"
                  placeholder="Full Name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- <b-col md="6">
            <b-form-group label="Title" label-for="title">
              <validation-provider #default="{ errors }" name="title">
                <b-form-input
                  id="title"
                  v-model="title"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Title of Petition"
                  disabled
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col> -->
          <b-col md="6">
            <b-form-group label="Subject" label-for="subject">
              <validation-provider #default="{ errors }" name="subject">
                <b-form-input
                  id="subject"
                  rows="2"
                  v-model="subject"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Enter Subject for the Petition"
                  disabled
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group>
              <quill-editor
                v-model="content"
                class="text-black"
                disabled
              />
            </b-form-group>
          </b-col>
        </b-row>
        <!-- <b-row>
          <b-col md="6">
            <b-form-checkbox id="checkbox-1" v-model="draft" name="checkbox-1">
              Draft
            </b-form-checkbox>
          </b-col>
        </b-row> -->
      </b-form>
    </validation-observer>
    <b-overlay :show="show" spinner-variant="primary" no-wrap />
  </b-modal>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import util from "../../util";
import { mapActions } from "vuex";
import Ripple from "vue-ripple-directive";
import { quillEditor } from "vue-quill-editor";
export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    quillEditor,
  },
  props: {
    petition: Object,
  },
  mixins: [util],
  directives: {
    Ripple,
  },
  data() {
    return {
      
      case_no: "",
      cnic: "",
      name: "",
      show: false,
      location: "",
      content:"",
      marked_to: {},
      draft: false,
      mark_options: [
        { value: 1, name: "COAS" },
        { value: 2, name: "AG Branch" },
        { value: 3, name: "PA" },
        { value: 4, name: "PS" },
        { value: 5, name: "W&R" },
        { value: 6, name: "Med" },
        { value: 7, name: "Housing" },
        { value: 8, name: "PP&A" },
        { value: 9, name: "Law" },
        { value: 10, name: "CP" },
        { value: 11, name: "Regs" },
        { value: 12, name: "JAG" },
      ],
      statusOptions: [
        { text: "Pending", value: 1 },
        { text: "Processing", value: 2 },
        { text: "Closed", value: 3 },
      ],
      status: "",
      subject: "",
      title: "",
    };
  },
  async mounted() {
    if (this.petition) {
      const marked_to_val = this.petition.marked_to;
      this.case_no = this.petition.case_no;
      this.cnic = this.petition.cnic;
      this.name = this.petition.name;
      this.content = this.petition.content;
      this.location =
        this.petition.location === 1
          ? "Inside"
          : this.petition.location === 2
          ? "Outside"
          : "Call Center";
      var found_organizaiton = this.mark_options.filter(function (item) {
        return item.value == marked_to_val;
      });
      this.marked_to = found_organizaiton[0];
      // {
      //   value: found_organizaiton.value,
      //   name: found_organizaiton.name,
      // };
      this.status =
        this.petition.status === 1
          ? "Pending"
          : this.petition.status === 2
          ? "Processing"
          : "Closed";
      this.subject = this.petition.subject;
      this.title = this.petition.title;
    }
  },

  methods: {
    ...mapActions("appData", ["partialUpdatePetition"]),

    async submit() {
      this.show = true;
      try {
        const res = await this.partialUpdatePetition({
          id: this.petition.id,
          cnic: this.cnic,
          name: this.name,
          marked_to: this.marked_to.value,
          subject: this.subject,
          title: this.title,
          draft: this.draft,
        });
        if (res.status === 200) {
          this.$swal({
            title: res.data.msg,
            icon: "success",
            timer: 1500,
          });
          this.show = false;
          this.$nextTick(() => {
            this.$bvModal.hide("petition-edit-modal");
          });
          this.$emit("modalClosed");
        } else {
          this.$swal({
            title: "Unable to update Petition",
            icon: "error",
            timer: 3000,
          });
        }
      } catch (error) {
        this.show = false;
        this.displayError(error);
      }
    },
    async validationForm() {
      const success = await this.$refs.petitionViewValidation.validate();
      if (success) {
        await this.submit();
      } else {
        this.$swal({
          title: "Please fill out all required fields",
          icon: "error",
          timer: 3000,
        });
      }
    },
  },
};
</script>


<style scoped>
[hidden] {
  display: none !important;
}

.wrapper {
  position: relative;
}
/* label {
  font-size: 110%;
  font-weight: 550 !important;
  color: #000;
} */

.overlay {
  position: absolute;
  bottom: 0px;
  text-align: center;
}
</style>
